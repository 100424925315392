import React from "react";
import { useRouterContext, useRouterType, useLink } from "@refinedev/core";
import { Typography, theme, Space } from "antd";
import type { RefineLayoutThemedTitleProps } from "@refinedev/antd";

const { useToken } = theme;

const defaultText = "Model Viewer";

const defaultIcon = (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Group">
            <rect id="Rectangle" x="0" y="0" width="24" height="24"></rect>
            <path d="M12,21 C16.9705627,21 21,16.9705627 21,12 C21,7.02943725 16.9705627,3 12,3 C7.02943725,3 3,7.02943725 3,12 C3,16.9705627 7.02943725,21 12,21 Z M12,23 C5.92486775,23 1,18.0751322 1,12 C1,5.92486775 5.92486775,1 12,1 C18.0751322,1 23,5.92486775 23,12 C23,18.0751322 18.0751322,23 12,23 Z" id="Oval-Copy" fill="#FFFFFF" fill-rule="nonzero"></path>
            <path d="M15.267627,8.88656534 L12.0625,7.03608439 C12.0238249,7.01375529 11.9761751,7.01375529 11.9375,7.03608439 L8.74222777,8.88087567 L12.0098548,10.767441 L15.267627,8.88656534 Z M16.330127,10.5825318 L13,12.5051815 L13,16.4226497 L16.267627,14.5360844 C16.3063022,14.5137553 16.330127,14.4724894 16.330127,14.4278312 L16.330127,10.5825318 Z M11,16.4226497 L11,12.4938022 L7.66987298,10.5711524 L7.66987298,14.4278312 C7.66987298,14.4724894 7.69369785,14.5137553 7.73237298,14.5360844 L11,16.4226497 L11,16.4226497 Z M17.267627,7.7318648 C17.9251043,8.11145949 18.330127,8.81297941 18.330127,9.57216878 L18.330127,14.4278312 C18.330127,15.1870206 17.9251043,15.8885405 17.267627,16.2681352 L13.0625,18.6959664 C12.4050227,19.0755611 11.5949773,19.0755611 10.9375,18.6959664 L6.73237298,16.2681352 C6.07489569,15.8885405 5.66987298,15.1870206 5.66987298,14.4278312 L5.66987298,9.57216878 C5.66987298,8.81297941 6.07489569,8.11145949 6.73237298,7.7318648 L10.9375,5.30403358 C11.5949773,4.92443889 12.4050227,4.92443889 13.0625,5.30403358 L17.267627,7.7318648 Z" id="Shape" fill="#FFFFFF" fill-rule="nonzero"></path>
        </g>
    </g>
  </svg>
);

export const ThemedTitle: React.FC<RefineLayoutThemedTitleProps> = ({
  collapsed,
  icon = defaultIcon,
  text = defaultText,
  wrapperStyles,
}) => {
  const { token } = useToken();
  const routerType = useRouterType();
  const Link = useLink();
  const { Link: LegacyLink } = useRouterContext();

  const ActiveLink = routerType === "legacy" ? LegacyLink : Link;

  return (
    <ActiveLink
      to="/"
      style={{
        display: "inline-block",
        textDecoration: "none",
      }}
    >
      <Space
        style={{
          display: "flex",
          alignItems: "center",
          fontSize: "inherit",
          ...wrapperStyles,
        }}
      >
        <div
          style={{
            height: "24px",
            width: "24px",
            color: token.colorPrimary,
          }}
        >
          {icon}
        </div>

        {!collapsed && (
          <Typography.Title
            style={{
              fontSize: "inherit",
              marginBottom: 0,
              fontWeight: 700,
            }}
          >
            {text}
          </Typography.Title>
        )}
      </Space>
    </ActiveLink>
  );
};
