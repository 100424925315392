import React from "react";
import { IResourceComponentsProps } from "@refinedev/core";
import { Create, useForm } from "@refinedev/antd";
import { Form, Input, Upload } from "antd";


export const Model3dCreate: React.FC<IResourceComponentsProps> = () => {
    const { formProps, saveButtonProps, onFinish } = useForm();

    const onFinishForm = (values: any) => {
        const formData = new FormData();
        formData.append("title", values.title);
        formData.append("description", values.description);
        formData.append("coverImage", values.coverImage.file);
        formData.append("model", values.model.file);
        formData.append("modelIos", values.modelIos.file);

        onFinish(formData);
    };
    
    return (
        <Create saveButtonProps={saveButtonProps}>
            <Form {...formProps} layout="vertical" onFinish={onFinishForm}>
                <Form.Item
                    label="Title"
                    name={["title"]}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Description"
                    name="description"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input.TextArea rows={5} />
                </Form.Item>
                
                <Form.Item
                    label="Model"
                    name={["model"]}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Upload.Dragger
                            beforeUpload={() => false}
                            name="model"
                            listType="picture"
                            maxCount={1}
                        >
                            <p className="ant-upload-text">
                                Drag & drop a file in this area
                            </p>
                    </Upload.Dragger>
                </Form.Item>
                <Form.Item
                    label="Model IOS"
                    name={["modelIos"]}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Upload.Dragger
                            beforeUpload={() => false}
                            name="modelIos"
                            listType="picture"
                            maxCount={1}
                        >
                            <p className="ant-upload-text">
                                Drag & drop a file in this area
                            </p>
                    </Upload.Dragger>
                </Form.Item>
                <Form.Item
                    label="Cover"
                    name={["coverImage"]}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Upload.Dragger
                            beforeUpload={() => false}
                            name="coverImage"
                            listType="picture"
                            maxCount={1}
                        >
                            <p className="ant-upload-text">
                                Drag & drop a file in this area
                            </p>
                    </Upload.Dragger>
                </Form.Item>
            </Form>
        </Create>
    );
};

