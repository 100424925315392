import {
  Refine,
  Authenticated,
} from "@refinedev/core";
import { RefineKbar, RefineKbarProvider } from "@refinedev/kbar";

import {
  AuthPage,
  ErrorComponent,
  notificationProvider,
} from "@refinedev/antd";
import "@refinedev/antd/dist/reset.css";

import { BrowserRouter, Route, Routes, Outlet } from "react-router-dom";
import routerBindings, {
  NavigateToResource,
  CatchAllNavigate,
  UnsavedChangesNotifier,
} from "@refinedev/react-router-v6";
import dataProvider from "@refinedev/simple-rest";
import { ColorModeContextProvider } from "./contexts/color-mode";

import { Model3dList } from "pages/models3d/list";
import { Model3dEdit } from "pages/models3d/edit";
import { Model3dShow } from "pages/models3d/show";
import { Model3dCreate } from "pages/models3d/create";

import { ThemedLayout } from "components/themedLayout";
import { ThemedSider } from "components/themedLayout/sider";
import { ThemedTitle } from "components/themedLayout/title"; 

import { Header } from "components";

import { HttpError } from "@refinedev/core";

import authProvider from "./authProvider";
import axios from "axios";
const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(function (config) {
  const token = localStorage.getItem('token');
  if (config.headers) config.headers.Authorization = token ? `Bearer ${token}` : '';
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const statusCode = error.response?.status;
    if (statusCode === 401 || statusCode === 403) {
      return window.location.href = '/login';
    }

    const customError: HttpError = {
      ...error,
      message: error.response?.data?.message,
      statusCode: statusCode,
    };

    return Promise.reject(customError);
  }
);

function App() {
  return (
    <BrowserRouter>
      <RefineKbarProvider>
        <ColorModeContextProvider>
          <Refine
            authProvider={authProvider}
            notificationProvider={notificationProvider}
            routerProvider={routerBindings}
            dataProvider={dataProvider(process.env.REACT_APP_API_URL || "", axiosInstance)}
            resources={[
              {
                  name: "model3d",
                  list: "/model3d",
                  show: "/model3d/show/:id",
                  create: "/model3d/create",
                  edit: "/model3d/edit/:id",
                  canDelete: true,
              },
            ]}
            options={{
              syncWithLocation: true,
              warnWhenUnsavedChanges: true,
            }}
          >
            <Routes>
                <Route
                    element={
                        <Authenticated
                            fallback={<CatchAllNavigate to="/login" />}
                        >
                            <ThemedLayout Header={Header} Sider={ThemedSider} Title={ThemedTitle}>
                                <Outlet />
                            </ThemedLayout>
                        </Authenticated>
                    }
                >
                <Route
                    index
                    element={
                        <NavigateToResource resource="model3d" />
                    }
                />
                <Route path="model3d">
                    <Route index element={<Model3dList />} />
                    <Route path="show/:id" element={<Model3dShow />} />
                    <Route path="edit/:id" element={<Model3dEdit />} />
                    <Route path="create" element={<Model3dCreate />} />
                </Route>
                <Route path="*" element={<ErrorComponent />} />
              </Route>
              <Route
                path="/login"
                element={
                  <AuthPage
                    type="login"
                    registerLink={false}
                    forgotPasswordLink={false}
                    rememberMe={false}
                    title={<ThemedTitle collapsed={false} />}
                  />
                }
              />
              <Route
                path="/register"
                element={
                  <AuthPage
                    type="register"
                    title={<ThemedTitle collapsed={false} />}
                  />
                }
              />
            </Routes>
            <RefineKbar />
            <UnsavedChangesNotifier />
          </Refine>
        </ColorModeContextProvider>
      </RefineKbarProvider>
    </BrowserRouter>
  );
}

export default App;
