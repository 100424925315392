import type { AuthBindings } from "@refinedev/core";
import axios from "axios";

const authProvider: AuthBindings = {
    login: async ({ email, password }) => {
      const form = {
        email: email,
        password: password
      };
      const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/user/signin`, form);
      if (data.status === parseInt('401')) {
        return {
          success: false,
          error: new Error("Invalid email or password"),
        };
      } else {
        localStorage.setItem('token', data.token);
        return {
          success: true,
          redirectTo: "/",
        };
      }
    },
    register: async ({ email, password }) => {
      const form = {
        fullname: "Sochi Park",
        email: email,
        password: password
      };
      const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/user/signup`, form);

      if (data.status === parseInt('201')) {
        return {
          success: true,
          redirectTo: "/login",
        };
      }

      return {
          success: false,
          error: {
              name: "Register Error",
              message: "User already exists",
          },
      };
    },
    check: async () => {
      const token = localStorage.getItem("token");

      if (token) {
          return {
              authenticated: true,
          };
      }

      return {
          authenticated: false,
          logout: true,
          redirectTo: "/login",
          error: new Error("User is not authenticated"),
      };
    },
    logout: async () => {
      localStorage.removeItem("token");
      return {
          success: true,
          redirectTo: "/login",
      };
    },
    onError: async (error) => {
      if (error.status === 401 || error.status === 403) {
          return {
              logout: true,
              redirectTo: "/login",
              error,
          };
      }

      return {};
    },
};

export default authProvider;
